import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

class SelectPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: props.data ? props.data : '',
    };
  }

  getPlan = () => {
    return this.state.selectedPlan;
  };

  onChange = (event) => {
    this.setState({
      selectedPlan: event.target.value,
    });
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const { selectedPlan } = this.state;
    const { plans = [], required, idPrefix } = this.props;

    return (
      <>
        <Form.Group className='form-input-group sm-mb10 xs-mb10'>
          <Form.Select
            id={idPrefix ? `${idPrefix}-plan-type` : 'plan-type'}
            size='md'
            className='font-family-secondary'
            onChange={this.onChange}
            value={selectedPlan}
            required={required}
          >
            <option disabled value=''>
              Choose Your Plan Type
            </option>
            {plans.map((plan, index) => {
              return (
                <option value={plan} key={index}>
                  {plan}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </>
    );
  }
}

SelectPlan.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  plans: PropTypes.array,
  data: PropTypes.string,
  idPrefix: PropTypes.string,
};

export default SelectPlan;
