import { Button, Col, Form, Row } from 'react-bootstrap';
import {
  GroupPlans,
  MedicareAdvantagePlans,
  MedigapPlans,
  OtherInsuranceWithProviders,
} from '../../constants/DropdownConstants';

import FormHeader from './formHeader';
import FormUtils from '../../utils/formUtils';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import MandatoryLabel from '../common/mandatoryLabel';
import PropTypes from 'prop-types';
import RadioInput from '../common/input/radioInput';
import React from 'react';
import SelectInsuranceProvider from '../common/input/selectInsuranceProvider';
import SelectInsuranceType from '../common/input/selectInsuranceType';
import SelectMonth from '../common/input/selectMonth';
import SelectPlan from '../common/input/selectPlan';
import SelectYear from '../common/input/selectYear';
import SidebarLayout from '../layout/sidebarLayout';
import { fireEvent } from '../../cra';

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrolledInMedicareB: '',
      enrolledMonth: '',
      enrolledYear: '',
      coveredByOtherIns: '',
      otherInsuranceType: '',
      insurancePlan: '',
      otherPlanType: '',
      insuranceProvider: '',

      formValidated: false,
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    fireEvent('click', event, {
      description: 'supplemental details for spouse/partner submitted',
    });
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      FormUtils.scrollToTop();
      this.setState({ formValidated: true });
      event.stopPropagation();
    } else {
      const {
        enrolledInMedicareB,
        enrolledMonth,
        enrolledYear,
        coveredByOtherIns,
        otherInsuranceType,
        insurancePlan,
        otherPlanType,
        insuranceProvider,
      } = this.state;
      let data = {
        spouse_enrolled_medicare_part_b: enrolledInMedicareB,
        spouse_covered_by_other: coveredByOtherIns,

        spouse_month_enrolled: enrolledMonth,
        spouse_year_enrolled: enrolledYear,

        spouse_other_insurance_type: otherInsuranceType,
        spouse_plan_type: insurancePlan,
        spouse_other_plan_type: otherPlanType,
        spouse_insurance_provider: insuranceProvider,
      };
      this.props.onSubmit(data);
    }
  };

  handleCoveredByOtherIns = (value) => {
    this.setState({ coveredByOtherIns: value });
    fireEvent('radioInput', value, {
      description: 'spouse/partner covered by other insurance?',
      value,
    });
  };

  handleEnrolledInMedicareB = (value) => {
    this.setState({ enrolledInMedicareB: value });
    fireEvent('radioInput', value, {
      description: 'spouse/partner enrolled in medicareB?',
      value,
    });
  };

  onEnrolledMonthChange = (value) => {
    this.setState({ enrolledMonth: value });
    fireEvent('textInput', value, {
      description: 'spouse/partner plan enrollment start date(month)',
      value,
    });
  };

  onEnrolledYearChange = (value) => {
    this.setState({ enrolledYear: value });
    fireEvent('textInput', value, {
      description: 'spouse/partner plan enrollment start date(year)',
      value,
    });
  };

  onInsuranceTypeChange = (value) => {
    this.setState({ otherInsuranceType: value });
    fireEvent('textInput', value, {
      description: 'other insurance type for spouse/partner',
      value,
    });
  };

  onInsurancePlanChange = (value) => {
    this.setState({ insurancePlan: value });
    fireEvent('textInput', value, {
      description: 'other insurance plan for spouse/partner',
      value,
    });
  };

  onInsuranceProviderChange = (value) => {
    this.setState({ insuranceProvider: value });
    fireEvent('textInput', value, {
      description: 'other insurance provider for spouse/partner',
      value,
    });
  };

  render() {
    const {
      enrolledInMedicareB,
      enrolledMonth,
      enrolledYear,
      coveredByOtherIns,
      otherInsuranceType,
      insurancePlan,
      otherPlanType,
      insuranceProvider,
      formValidated,
    } = this.state;
    return (
      <SidebarLayout securityIcons subFooter>
        <FormHeader text='Spouse/Partner Supplemental Information' />
        <Form
          id='step-5-form'
          noValidate
          onSubmit={this.onSubmit}
          validated={formValidated}
        >
          <Row>
            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                Are they currently enrolled in Medicare Part B?
                <MandatoryLabel />
              </p>
              <RadioInput
                options={[
                  {
                    id: 'yes',
                    label: 'Yes',
                    value: 'yes',
                  },
                  {
                    id: 'no',
                    label: 'No',
                    value: 'no',
                  },
                ]}
                id='enrolled-medicare-b'
                name='enrolled-medicare-b'
                onChange={this.handleEnrolledInMedicareB}
                value={enrolledInMedicareB}
              />
            </Col>
            <Col xs={12}>
              {enrolledInMedicareB !== '' && (
                <Row>
                  <Col className='mc-form-input' xs={12}>
                    <p className='form-input-label'>
                      {enrolledInMedicareB === 'yes'
                        ? 'Enter the date enrolled into Medicare Part B.'
                        : 'When does their Medicare Part B take effect?'}
                    </p>
                    <p className='form-input-description p16 mb15 mt-15'>
                      If you aren&apos;t sure of the actual date, please make an
                      estimate.
                    </p>
                  </Col>
                  <Col xs={12} sm={6}>
                    <SelectMonth
                      idPrefix='enrolled'
                      data={enrolledMonth}
                      onChange={this.onEnrolledMonthChange}
                      required={true}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <SelectYear
                      idPrefix='enrolled'
                      reverse={true}
                      data={enrolledYear}
                      onChange={this.onEnrolledYearChange}
                      required={true}
                    />
                  </Col>
                  <Col xs={12} sm={12}>
                    <hr className='form-hr mt40' />
                  </Col>
                </Row>
              )}
            </Col>

            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                Is your spouse/partner covered by other health insurance?
                <MandatoryLabel />
              </p>
              <RadioInput
                options={[
                  {
                    id: 'yes',
                    label: 'Yes',
                    value: 'yes',
                  },
                  {
                    id: 'no',
                    label: 'No',
                    value: 'no',
                  },
                ]}
                id='covered-other-insurance'
                name='covered-other-insurance'
                onChange={this.handleCoveredByOtherIns}
                value={coveredByOtherIns}
              />
            </Col>
            <Col xs={12}>
              {coveredByOtherIns === 'yes' && (
                <>
                  <Row>
                    <Col className='mc-form-input' xs={12}>
                      <p className='form-input-label'>
                        Please choose which type of insurance they currently
                        have
                      </p>
                      <Row>
                        <Col xs={12} sm={{ offset: 1, span: 10 }}>
                          <SelectInsuranceType
                            data={otherInsuranceType}
                            onChange={this.onInsuranceTypeChange}
                            required={true}
                          />
                        </Col>

                        <Col xs={12} sm={{ offset: 1, span: 10 }}>
                          {otherInsuranceType ===
                          'Medicare Supplement (Medigap)' ? (
                            <SelectPlan
                              plans={MedigapPlans}
                              onChange={this.onInsurancePlanChange}
                              required={true}
                              data={insurancePlan}
                            />
                          ) : otherInsuranceType ===
                            'Medicare Advantage (HMO, PPO, PFFS)' ? (
                            <SelectPlan
                              plans={MedicareAdvantagePlans}
                              onChange={this.onInsurancePlanChange}
                              required={true}
                              data={insurancePlan}
                            />
                          ) : otherInsuranceType === 'Group' ||
                            otherInsuranceType === 'Individual Health' ? (
                            <SelectPlan
                              plans={GroupPlans}
                              onChange={this.onInsurancePlanChange}
                              required={true}
                              data={insurancePlan}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {insurancePlan === 'Other' && (
                    <Row>
                      <Col className='mc-form-input' xs={12}>
                        <p className='form-input-label'>
                          Please indicate their OTHER plan type:
                        </p>
                        <Form.Group className='form-input-group sm-mb10 xs-mb10'>
                          <Form.Control
                            placeholder='Your other plan type'
                            type='text'
                            onChange={(event) => {
                              this.setState({
                                otherPlanType: event.target.value,
                              });
                            }}
                            value={otherPlanType}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {OtherInsuranceWithProviders.includes(otherInsuranceType) && (
                    <Row>
                      <Col className='mc-form-input' xs={12}>
                        <p className='form-input-label'>
                          Who is their current health insurance provider?
                        </p>
                        <SelectInsuranceProvider
                          onChange={this.onInsuranceProviderChange}
                          required={true}
                          data={insuranceProvider}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Col>

            <Col xs={12} md={6} lg={5} className='mc-form-input'>
              <Button
                className='btn btn-primary-custom w-100 mt20'
                type='submit'
                id='step-4-submit-btn'
              >
                <span>
                  Continue
                  <HiOutlineArrowNarrowRight
                    color='white'
                    scale={3}
                    style={{ marginLeft: 10 }}
                  />
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
      </SidebarLayout>
    );
  }
}

Step5.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
export default Step5;
