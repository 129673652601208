import React from 'react';
import SupplementNavigation from '../component/forms/supplementNavigation';

class Supplement extends React.Component {
  render() {
    return <SupplementNavigation></SupplementNavigation>;
  }
}

export default Supplement;
