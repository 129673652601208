const OtherInsuranceOptions = [
  'Medicare Supplement (Medigap)',
  'Medicare Advantage (HMO, PPO, PFFS)',
  'Original Medicare A & B Only',
  'Group',
  'Retiree',
  'Cobra',
  'Individual Health',
  'Medicaid',
  'Tricare',
  'None ',
];

const OtherInsuranceWithProviders = [
  'Medicare Supplement (Medigap)',
  'Medicare Advantage (HMO, PPO, PFFS)',
  'Group',
  'Retiree',
  'Cobra',
  'Individual Health',
];

const MedigapPlans = [
  'Plan F',
  'Plan G',
  'Plan G High Deductible',
  'Plan N',
  'Plan A',
  'Plan B',
  'Plan C',
  'Plan D',
  'Plan F High Deductible',
  'Plan K',
  'Plan L',
  'Plan M',
  'Other',
  "I'm Not Sure",
];

const MedicareAdvantagePlans = [
  'HMO (Health Maintenance Organization)',
  'PPO (Preferred Provider Organization)',
  'PFFS (Private Fee-For-Service)',
  'SNP (Special Needs Plan)',
  'Other',
];

const GroupPlans = [
  'HMO (Health Maintenance Organization)',
  'PPO (Preferred Provider Organization)',
  'EPO (Exclusive Provider Organization)',
  'POS (Point of Service)',
  'HSA (Health Savings Account)',
  'Other',
  "I'm Not Sure",
];

const States = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Armed Forces Americas',
  'Armed Forces Europe',
  'Armed Forces Pacific',
];

export {
  OtherInsuranceOptions,
  OtherInsuranceWithProviders,
  MedigapPlans,
  MedicareAdvantagePlans,
  GroupPlans,
  States,
};
